import React from 'react'

export const DoubleColumnElement = ({rowElement,rowElementresult}) => {
  return (
    <>
    
  

     
      <li className='flex justify-between text-sm md:text-[17px] border border-b-slate-400 p-2 pl-4 bg-slate-50 pr-2 '>
        {rowElement} <p className='min-w-[45%] max-w-[45%] border-l-2 border-slate-600 pl-4 font-bold md:font-semibold text-slate-900' >{rowElementresult}</p>
      </li>
      
      
 
      
     
    </>
  )
}
export default DoubleColumnElement;