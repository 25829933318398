import React from 'react';
import { FaFacebook, FaYoutube, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const AboutUs = () => {
  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl lg:text-4xl font-bold text-center text-gray-800 mb-6">About Us</h1>

        <div>
          <h2 className="text-lg lg:text-2xl font-semibold text-gray-700 mb-4">Welcome to SarkariLock.in</h2>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            At SarkariLock.in, we're dedicated to providing a comprehensive platform for government job seekers. Our mission is to offer an all-encompassing resource that goes beyond mere job updates. We provide detailed guides, exam preparation materials, and insider tips to give you an edge in your career journey.
          </p>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            Our platform not only covers the latest job openings but also includes in-depth information about various government schemes and policies. We aim to keep you informed about the benefits and opportunities available, ensuring you're always ahead of the curve.
          </p>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            With a focus on accuracy and reliability, SarkariLock.in strives to be your go-to resource for all things related to government exams and schemes. Join our community and take advantage of our carefully curated content designed to support your success.
          </p>

          <h3 className="text-xl font-semibold text-gray-700 mt-6">Our Commitment</h3>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            We are committed to providing timely and accurate information to help you navigate the complexities of government job preparation. Our team works tirelessly to update and refine our content to ensure that you have access to the best resources available.
          </p>

          <h3 className="text-xl font-semibold text-gray-700 mt-6">Stay Connected</h3>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            For updates and more information, follow us on our social media channels:
          </p>
          <ul className='flex gap-4 mt-2 list-none justify-center'>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61564607174252&mibextid=ZbWKwL" aria-label="Facebook" target="_blank" rel="noopener noreferrer" className='text-blue-600 hover:text-blue-800 transition duration-300'>
                <FaFacebook size={28} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@sarkarilock1" aria-label="YouTube" target="_blank" rel="noopener noreferrer" className='text-red-600 hover:text-red-800 transition duration-300'>
                <FaYoutube size={28} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/@sarkarilock" aria-label="Twitter" target="_blank" rel="noopener noreferrer" className='text-blue-400 hover:text-blue-600 transition duration-300'>
                <FaXTwitter size={28} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/@sarkarilock?igsh=MW1hNDV6cW11ZjBia==" aria-label="Instagram" target="_blank" rel="noopener noreferrer" className='text-pink-600 hover:text-pink-800 transition duration-300'>
                <FaInstagram size={28} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
