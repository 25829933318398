import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
export const ExploreLink = ({ category, examName, setLinkFormData, linkFormData, handleDelete }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
const navigate = useNavigate()
  useEffect(() => {
    const fetchLinkData = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const response = await fetch(
          `${config.BASE_URL}/api/admin/one/explore/link/${category}-${examName}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token
            },
          },
          
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch link data');
        }

        const result = await response.json();
        setLinkFormData({
          heading: result.data.exploreLink.heading || "",
          linkLabel: result.data.exploreLink.linkLabel || "",
          urlName: result.data.exploreLink.urlName || "",
          examName: result.data.exploreLink.examName || "",
          identifier: result.data.exploreLink.identifier|| "",
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLinkData();
  }, [category, examName, setLinkFormData]);

  const handleChange = (e) => {
    setLinkFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Conditional rendering based on loading/error state
  if (loading) {
    return <div className="text-center py-4">Loading data...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        Error: {error}
      </div>
    );
  }
  const handleLinkDelete = async () => {
    await handleDelete(
      `${config.BASE_URL}/api/admin/one/explore/link/${category}-${examName}`,
      "/admin/explore/link",
      navigate
    )};
  return (
    <>
      {/* EXPLORE LINK Header with Delete Button */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Edit ExploreLink Schema Data</h2>
        <button
          className="text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md shadow-lg text-sm"
          onClick={() =>
            handleLinkDelete
          }
          aria-label="Delete Link"
          type='button'
        >
          Delete Link
        </button>
      </div>

      {/* Form Fields */}
      <div className="space-y-4">
        <div>
          <label className="block font-medium text-gray-700 mb-1">Link Label</label>
          <input
            type="text"
            name="linkLabel"
            value={linkFormData.linkLabel}
            className="bg-green-300 text-white font-mono font-bold rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-green-500"
            onChange={handleChange}
            aria-label="Link Label"
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-1">Heading (immutable)</label>
          <input
            type="text"
            name="heading"
            value={linkFormData.heading}
            className="bg-red-300 text-white font-mono font-bold rounded-md p-2 w-full cursor-not-allowed"
            readOnly
            aria-readonly="true"
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-1">Url Name (immutable)</label>
          <input
            type="text"
            name="urlName"
            value={linkFormData.urlName}
            className="bg-red-300 text-white font-mono font-bold rounded-md p-2 w-full cursor-not-allowed"
            readOnly
            aria-readonly="true"
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-1">Exam Name (immutable)</label>
          <input
            type="text"
            name="examName"
            value={linkFormData.examName}
            className="bg-red-300 text-white font-mono font-bold rounded-md p-2 w-full cursor-not-allowed"
            readOnly
            aria-readonly="true"
          />
        </div>

        <div>
          <label className="block font-medium text-gray-700 mb-1">Identifier (immutable)</label>
          <input
            type="text"
            name="identifier"
            value={linkFormData.identifier}
            className="bg-red-300 text-white font-mono font-bold rounded-md p-2 w-full cursor-not-allowed"
            readOnly
            aria-readonly="true"
          />
        </div>
      </div>
    </>
  );
};
