import React from 'react';
import { MdDashboardCustomize } from "react-icons/md";

export const DefaultPage = () => {
  return (
    <div className='flex items-center justify-center w-full h-full bg-gray-50'>
      <div className='flex flex-col items-center'>
        <MdDashboardCustomize className='text-8xl text-green-500 mb-2 animate-pulse' />
        <p className='text-center text-slate-600 text-lg'>Please select a row from Sidebar</p>

      </div>
    </div>
  );
}

export default DefaultPage;
