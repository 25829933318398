import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import { handleDataFileSubmit } from '../../utils/UtilsAdmin';
import config from '../../../config';
export const ExploreOverviewStructure = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [combinedLink, setCombinedLink] = useState([]);
  const [formData, setFormData] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(file);
    }
  };

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const token = localStorage.getItem('jwt');
        const response = await fetch(`${config.BASE_URL}/api/admin/explore/link/`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const result = await response.json();
        setCombinedLink(result.data.exploreLink); // Set combinedLink with fetched data
        setResults(result.data.exploreLink); // Initialize results with all links
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchLinks(); // Call the async function inside useEffect
  }, []);
  
  // Fuse.js configuration
  const options = {
    keys: ["url", "linkLabel"],
    threshold: 0.3, 
  };

  const fuse = new Fuse(combinedLink, options);

  // Handle search input
  const handleSearch = (e) => {
    const value = e.target.value[0];
    setQuery(value);

    if (value) {
      const fuseResults = fuse.search(value);
      setResults(fuseResults.map(result => result.item));
    } else {
      setResults(combinedLink); // Reset to full list if search is cleared
    }
  };

  
  const handleSubmit = async () => {
    handleDataFileSubmit(`${config.BASE_URL}/api/admin/explore/overview`,formData,setFormData)  
  }
  
  return (
    <>
      <div className='ml-10'>
        <p className='text-gray-500 text-2xl p-2'>Select the exam</p>
        <div className='flex'>
          <div className='bg-gradient-to-tl from-gray-100 to-gray-50 p-2 shadow w-96 rounded'>
            <input
              type="text"
              placeholder="Search..."
              value={query}
              onChange={handleSearch}
              className="w-[75%] outline-none shadow pl-3 p-1 rounded-md"
            />
            <button className='border shadow ml-2 px-2 p-1 rounded hover:bg-slate-50'>Search</button>
            <button className='bg-purple-500 px-4 py-2 rounded shadow-lg text-white mt-6 ml-6' onClick={handleSubmit}>Submit Files</button>
          </div>
          <div className="ml-4 bg-pink-300 m-2 rounded shadow-xl">
            <label className="block font-medium text-gray-700 mb-2 m-2">Upload New Overview (Only accept .json)</label>
            <input
              type="file"
              accept=".json"
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Display search results */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 m-2 lg:w-[90%]">
          {results.length > 0 ? (
            results.map((item, index) => (
              <div
                key={index}
                className="p-2 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition duration-200 text-center"
              >
                <Link
                  to={`detail/${item.examName}`}
                  className="text-sm lg:text-[16px] hover:text-blue-700 font-semibold text-blue-500"
                >
                  {item.linkLabel}
                </Link>
              </div>
            ))
          ) : (
            <div className="text-center text-red-600 text-lg lg:text-xl col-span-full p-4 bg-red-50 border border-red-200 rounded-lg">
              <p className="font-semibold">No results found</p>
              <p className="mt-2 text-gray-700">Try a different search term or check back later.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExploreOverviewStructure;
