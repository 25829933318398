import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdminPanelStructure from "../AdminPanelStructure";
import { Editor } from "@tinymce/tinymce-react";
import { ExploreLink } from "./ExploreLink";
import { handleDelete } from "../../utils/UtilsAdmin";
import config from "../../../config";
const ExploreMainDetails = () => {
  const { category, examName } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mainHead: "",
    firstPara: "",
    secondPara: "",
    thirdPara: "",
    metaDataKeyword: "",
    metaDataDescription: "",
    metaDataTitle: "",
    url: "",
    loading: true,
    error: null,
  });

  const [linkFormData, setLinkFormData] = useState({
    heading: "",
    linkLabel: "",
    urlName: "",
    examName: "",
    identifier: "",
  });

  // Fetch data from backend
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const response = await fetch(
        `${config.BASE_URL}/api/admin/explore/main/${category}-${examName}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();

      setFormData((prevState) => ({
        ...prevState,
        mainHead: result.data.Main.mainHead,
        firstPara: result.data.Main.firstPara,
        secondPara: result.data.Main.secondPara,
        thirdPara: result.data.Main.thirdPara,
        metaDataKeyword: result.data.Main.metaDataKeywords || "",
        metaDataDescription: result.data.Main.metaDataDescription || "",
        metaDataTitle: result.data.Main.metaDataTitle || "",
        url: result.data.Main.urlName || "",
        loading: false,
      }));
    } catch (err) {
      setFormData((prevState) => ({
        ...prevState,
        error: err.message,
        loading: false,
      }));
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [category, examName]);

  // Handle text changes for TinyMCE editor
  const handleTextChange = useCallback((field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


 // Form submission
const handleSubmit = async (e) => {
  e.preventDefault();

  if (!formData) {
    alert("Please fill all required fields.");
    return;
  }

  const token = localStorage.getItem("jwt"); // Retrieve the token

  if (!token) {
    alert("Authentication token is missing. Please log in again.");
    navigate("/login"); // Redirect to login if token is missing
    return;
  }

  try {
    const response = await fetch(
      `${config.BASE_URL}/api/admin/explore/main/${category}-${examName}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Include the token
        },
        body: JSON.stringify(formData),
        credentials: "include",
      }
    );

    const linkResponse = await fetch(
      `${config.BASE_URL}/api/admin/one/explore/link/${category}-${examName}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Include the token
        },
        body: JSON.stringify(linkFormData),
        credentials: "include",
      }
    );

    if (!response.ok || !linkResponse.ok) {
      throw new Error("Failed to submit data");
    }

    alert("Data submitted successfully!");
  } catch (err) {
    alert(`Error submitting data: ${err.message}`);
  }
};


  // Delete the main content and re-fetch data
  const handleMainDelete = async () => {
    try {
      await handleDelete(
        `${config.BASE_URL}/api/admin/explore/main/${category}-${examName}`,
        
        navigate
      );
      // Re-fetch data to update the UI
      fetchData();
    } catch (err) {
      alert(`Error deleting data: ${err.message}`);
    }
  };

  // Delete the link content and re-fetch data
  const handleLinkDelete = async () => {
    try {
      await handleDelete(
        `${config.BASE_URL}/api/admin/one/explore/link/${category}-${examName}`,
        "/admin/explore/link",
        navigate
      );
      // Re-fetch data to update the UI
      fetchData();
    } catch (err) {
      alert(`Error deleting link data: ${err.message}`);
    }
  };

  if (formData.loading) return <div>Loading...</div>;
  if (formData.error) return <div>Error: {formData.error}</div>;

  // Conditional rendering in case the main data is missing
  if (!formData.mainHead) return <div>No Data Available</div>;

  return (
    <AdminPanelStructure
      rightSidecontent={
        <div className="explore-main-editor p-6 bg-gray-100 rounded-lg shadow-lg">
          <div className="flex justify-between pr-10">
            <h2 className="text-2xl font-bold mb-4">Edit ExploreMain Schema Data</h2>
            <button
              className="text-white bg-red-500 px-4 rounded-md shadow-lg text-sm hover:bg-red-600"
              type="button"
              onClick={handleMainDelete}
            >
              Delete Main
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Main Head */}
            <div>
              <label className="block font-medium text-gray-700 mb-2">Main Head</label>
              <input
                type="text"
                name="mainHead"
                value={formData.mainHead}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* TinyMCE Editors for Paragraphs */}
            {["firstPara", "secondPara", "thirdPara"].map((field, index) => (
              <div key={field}>
                <label className="block font-medium text-gray-700 mb-2">
                  {`Paragraph ${index + 1} ${index === 2 ? "(Optional)" : ""}`}
                </label>
                <Editor
                  apiKey="lglew046c5at8atgw01jhx6pbtvjul9kg0wttt8yse5om068"
                  value={formData[field]}
                  onEditorChange={(content) => handleTextChange(field, content)}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help",
                  }}
                />
              </div>
            ))}

            {/* Metadata Inputs */}
            {[
              { label: "MetaData Keywords", name: "metaDataKeyword" },
              { label: "MetaData Description", name: "metaDataDescription" },
              { label: "MetaData Title", name: "metaDataTitle" },
            ].map(({ label, name }) => (
              <div key={name}>
                <label className="block font-medium text-gray-700 mb-2">{label}</label>
                <input
                  type="text"
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            ))}

            {/* URL (immutable) */}
            <div>
              <label className="block font-medium text-gray-700 mb-2">URL (immutable)</label>
              <input
                type="text"
                name="url"
                value={formData.url}
                className="bg-red-300 text-white font-mono font-bold rounded-md p-2 w-full"
                readOnly
              />
            </div>

            {/* Link Data Component */}
            <ExploreLink
              category={category}
              examName={examName}
              setLinkFormData={setLinkFormData}
              linkFormData={linkFormData}
              handleDelete={handleLinkDelete} // Use modified delete handler for links
            />
            

            {/* Submit Button */}
            <button
              type="submit"
              disabled={formData.loading}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
            >
              {formData.loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      }
    />
  );
};

export default ExploreMainDetails;
