import React from 'react';
import { Link } from 'react-router-dom';

const EasyNavigationButton = () => {
  const wordLimit = (para) => {
    if (para.length > 28 || para.length < 24) {
      return `Word limit is 18-26 including spaces. Your text has ${para.length} characters.`;
    } else {
      return para;
    }
  };

  const para1 = { label: "SSC-CGL 2024 latest news  ", labelLink: "/job/ssc-cgl" };
  const para2 = { label: "SSC-MTS latest news 2024   ", labelLink: "/job/ssc-mts" };
  const para3 = { label: "RRb junior Engineer Exam  ", labelLink: "/job/rrb-junior-engineer-je" };
  const para4 = { label: "RRB NTPC latest new 2024  ", labelLink: "/job/rrb-ntpc-non-technical-popular-categories" };
  const para5 = { label: "Up police SIASI 2024    ", labelLink: "/job/up-sias-i-senior-industrial-assistant-sub-inspector" };
  const para6 = { label: "SSC Chsl exam news 2024  ", labelLink: "/job/ssc-chsl" };
  const para7 = { label: "About bihar police 2024  ", labelLink: "/job/bihar-police-si" };
  const para8 = { label: "Bihar civil court 2024  " , labelLink: "/job/bihar-district-civil-court" };

  return (
    <div className='mt-4 mb-4 pb-2 bg-green-50 shadow-md'>
      <h2 className='text-center text-2xl p-4 font-bold underline'>Top on SarkariLock</h2>
      <ul className='flex flex-wrap gap-x-5 sm:gap-x-7 gap-2 m-2 p-2 justify-center text-lg list-none'>
        <li>
          <Link to={para1.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-purple-700 to-purple-300 text-slate-200 text-center hover:from-purple-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para1.label)}
          </Link>
        </li>
        <li>
          <Link to={para2.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-orange-700 to-orange-300 text-slate-200 text-center hover:from-orange-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para2.label)}
          </Link>
        </li>
        <li>
          <Link to={para3.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-sky-700 to-sky-300 text-slate-200 text-center hover:from-sky-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para3.label)}
          </Link>
        </li>
        <li>
          <Link to={para4.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-red-700 to-red-300 text-slate-200 text-center hover:from-red-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para4.label)}
          </Link>
        </li>
        <li>
          <Link to={para5.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-yellow-700 to-yellow-300 text-slate-200 text-center hover:from-yellow-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para5.label)}
          </Link>
        </li>
        <li>
          <Link to={para6.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-blue-700 to-blue-300 text-slate-200 text-center hover:from-blue-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para6.label)}
          </Link>
        </li>
        <li>
          <Link to={para7.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-emerald-700 to-emerald-300 text-slate-200 text-center hover:from-emerald-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para7.label)}
          </Link>
        </li>
        <li>
          <Link to={para8.labelLink} className='block px-3 rounded-lg py-1 md:text-lg text-sm bg-gradient-to-br from-pink-700 to-pink-300 text-slate-200 text-center hover:from-pink-500 hover:to-slate-400 easy-navigation-button-small-screen shadow-lg'>
            {wordLimit(para8.label)}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default EasyNavigationButton;
