import React from 'react'
import DoubleColumnElement from '../DoubleColumnElement';
const AgeLimit = ({ageLimitData}) => {
  return (
    <><div className='p-2 md:p-4 shadow-lg mt-2'>
    <h2 className='font-semibold text-xl sm:text-2xl text-center p-2 bg-gradient-to-br from-sky-900 to-sky-400 text-white'>
      Age Limit
    </h2>
    <ol className='list-none'>
    {ageLimitData.map((date, index) => (
      <DoubleColumnElement
        key={index}
        rowElement={date.rowElement}
        rowElementresult={date.rowElementresult}
      />
    ))}
    </ol>
    <p className='font-semibold text-green-700 text-xl text-center lg:text-2xl p-2'>Age relaxation as per the rules.</p>
  </div></>
  )
}
export default AgeLimit;