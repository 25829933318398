import React from 'react';
import { Link } from 'react-router-dom';
import useFetchLinks from '../useFetchLinks';
import config from '../../../../../../config';
const SeeMoreLinkLatestJob = () => {
  const { linkData: latestJobLinks, loading, error } = useFetchLinks(`${config.BASE_URL}/api/explore/link/latestJob`);

  return (
    <div className="container mx-auto p-4 pt-0 bg-slate-50">
      {loading && <p>Loading Latest Job Links...</p>}
      {error && <p>Error loading Latest Job Links: {error}</p>}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 m-2 lg:w-[60%]">
        {latestJobLinks && latestJobLinks.length > 0 ? (
          latestJobLinks.map((item, index) => (
            <div key={index} className="p-2 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition duration-200 text-center">
              <Link to={item.url} className="text-sm lg:text-[16px] hover:text-blue-700 font-semibold text-blue-500">
                {item.linkLabel}
              </Link>
            </div>
          ))
        ) : (
          <p>No latest job links available.</p>
        )}
      </div>
    </div>
  );
};

export default SeeMoreLinkLatestJob;
