import React from 'react'
import AdminPanelStructure from '../AdminPanelStructure';
import ExploreOverviewStructure from './ExploreOverviewStructure';
const ExploreOverview = () => {
  return (
    <>
    <AdminPanelStructure rightSidecontent={<ExploreOverviewStructure/>}/>
    </>
  )
}
export default ExploreOverview;