import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

const LoginPage = () => {
    const { setIsAuthenticated } = useAuth();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [lockoutTime, setLockoutTime] = useState(0);
    const [attempts, setAttempts] = useState(0);
    const navigate = useNavigate();

    const lockoutDurations = [
        1 * 60 * 1000,     // 1 minute
        5 * 60 * 1000,     // 1 minute
        60 * 60 * 1000,    // 1 hour
        10 * 60 * 60 * 1000, // 10 hours
        7 * 24 * 60 * 60 * 1000, // 7 days
    ];

    const formatRemainingTime = (time) => {
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const days = Math.floor(time / (1000 * 60 * 60 * 24));

        let result = '';
        if (days > 0) result += `${days} day${days > 1 ? 's' : ''}, `;
        if (hours > 0) result += `${hours} hour${hours > 1 ? 's' : ''}, `;
        result += `${minutes} minute${minutes > 1 ? 's' : ''}, ${seconds} second${seconds > 1 ? 's' : ''}`;
        return result;
    };

    useEffect(() => {
        const storedAttempts = localStorage.getItem('failedAttempts');
        const storedLockoutTime = localStorage.getItem('lockoutTime');

        if (storedAttempts) {
            setAttempts(Number(storedAttempts));
        }

        if (storedLockoutTime) {
            setLockoutTime(Number(storedLockoutTime));
        }

        if (Number(storedLockoutTime) > Date.now()) {
            setError(`You are locked out. Try again in ${formatRemainingTime(Number(storedLockoutTime) - Date.now())}.`);
        }
    }, []);

    useEffect(() => {
      const checkSession = async () => {
        const token = localStorage.getItem('jwt'); // Get the JWT from local storage
        try {
            const response = await fetch(`${config.BASE_URL}/api/admin/auth/status`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
    
            if (result.isAuthenticated) {
                setIsAuthenticated(true);
                navigate('/admin-dashboard');
            }
        } catch (err) {
            console.error('Session check failed:', err);
        }
    };
    
        checkSession();
    }, [navigate, setIsAuthenticated]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (lockoutTime > Date.now()) {
            setError(`You are locked out. Try again in ${formatRemainingTime(lockoutTime - Date.now())}.`);
            return;
        }

        if (!userName || !password) {
            setError('Please enter both username and password.');
            return;
        }

        setError('');
        setLoading(true);

        try {
            const response = await fetch(`${config.BASE_URL}/api/admin/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ userName, password }),
            });

            if (!response.ok) {
                const newAttempts = attempts + 1;
                setAttempts(newAttempts);
                localStorage.setItem('failedAttempts', newAttempts);

                if (newAttempts >= lockoutDurations.length) {
                    const newLockoutTime = Date.now() + lockoutDurations[lockoutDurations.length - 1];
                    setLockoutTime(newLockoutTime);
                    localStorage.setItem('lockoutTime', newLockoutTime);
                    setError('You have exceeded the maximum number of login attempts. Please try again later.');
                } else {
                    const newLockoutTime = Date.now() + lockoutDurations[newAttempts - 1];
                    setLockoutTime(newLockoutTime);
                    localStorage.setItem('lockoutTime', newLockoutTime);
                    setError(`Login failed. You have ${3 - newAttempts} attempts left before being locked out.`);
                }
                return;
            }

            const { token } = await response.json(); // Assume the server returns a JWT token
            localStorage.setItem('jwt', token); // Store JWT in local storage
            setAttempts(0);
            localStorage.removeItem('failedAttempts');
            setIsAuthenticated(true);
            navigate('/admin-dashboard', { replace: true });
        } catch (err) {
            setError(err.message || 'Something went wrong');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold text-center text-gray-700">Login</h2>
                <form onSubmit={handleSubmit} className="mt-6">
                    <div className="mb-4">
                        <label htmlFor="userName" className="block text-gray-600 font-medium mb-2">Username</label>
                        <input
                            type="text"
                            id="userName"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value.toLowerCase())}
                            placeholder="Enter your username"
                            required
                        />
                    </div>

                    <div className="mb-6">
                        <label htmlFor="password" className="block text-gray-600 font-medium mb-2">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>

                    {error && <p className="text-red-500 text-center">{error}</p>}

                    <button
                        type="submit"
                        className="w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                        disabled={loading}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
