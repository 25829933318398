import React from 'react';
import { IoLogOutOutline } from "react-icons/io5";
import AdminSideBar from './AdminSideBar';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';


const AdminPanelStructure = ({ rightSidecontent }) => {
  const {logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    try {
        // Call the logout function to clear the token
        logout();

        // Alert the user and navigate to login
        alert("Logout successfully.");
        navigate('/admin-login', { replace: true });
    } catch (err) {
        alert(err.message || 'Something went wrong');
    }
};

  return (
    <>
      <div className='flex justify-between text-2xl font-semibold bg-gradient-to-r from-green-600 to-sky-500 text-slate-100 p-3 pl-16 pr-16'>
        <h1>Sarkarilock Administration</h1>
        <span className='flex gap-2 hover:text-slate-700 hover:scale-105' onClick={handleLogout}>
          logout <IoLogOutOutline size='30px' />
        </span>
      </div>

      <div className='flex h-[85vh]'>
        <AdminSideBar />
        <div className='overflow-y-scroll p-2 w-full'>
          {rightSidecontent}
        </div>
      </div>
    </>
  );
};

export default AdminPanelStructure;
