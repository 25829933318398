import React from 'react';
import TextSlider from './home-components/slider/TextSlider';
import EasyNavigationButton from './home-components/EasyNavigationButton';
import ExploreList from './home-components/explore/ExploreList';
import SarkariYojanas from './home-components/SarkariYojanas';

const Home = () => {


  return (
    <div>
  

      {/* Other Components */}
      <TextSlider />
      <EasyNavigationButton />

      <ExploreList />
   
      <SarkariYojanas />

      {/* Informational Section */}
      <div className="m-4 p-4 sm:p-6 border rounded-xl shadow-lg bg-white text-gray-800 text-base sm:text-lg leading-relaxed">
        <h2 className="bg-gradient-to-r from-blue-600 to-green-500 text-center p-3 font-bold text-xl sm:text-2xl text-white rounded-lg shadow-md">
          How We Are Different from Others
        </h2>
        <div className="p-4 sm:p-6 text-sm sm:text-md bg-green-50 rounded-lg mt-4">
          <p className="mb-4">
            At <strong>SarkariLock.in</strong>, we elevate government job preparation with a platform designed for your success. Unlike typical sites that offer only basic updates like exam results and admit cards, we provide real-time, accurate information to keep you ahead. Our comprehensive approach includes expertly crafted preparation guides and strategies to ensure you're confident and well-prepared for your exams.
          </p>

          <p className="mt-4">
            In addition to exam resources, we offer detailed coverage of government schemes (<strong>Sarkari-Yojanas</strong>), giving you valuable insights into various initiatives and benefits. Our goal is to make your preparation journey seamless by providing all the necessary resources and exclusive content in one place. At <strong>SarkariLock.in</strong>, you're not just accessing information; you're joining a community dedicated to your growth and success in the government sector.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
