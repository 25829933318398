import React, { createContext, useState, useEffect, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Check if there's a token in localStorage
        return localStorage.getItem('jwt') !== null;
    });

    useEffect(() => {
        const token = localStorage.getItem('jwt');
        if (token) {
            const decodedToken = jwtDecode(token);
            const isExpired = decodedToken.exp * 1000 < Date.now(); // exp is in seconds
            if (!isExpired) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                localStorage.removeItem('jwt'); // Optionally clear the expired token
            }
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('jwt'); // Clear the token
        setIsAuthenticated(false); // Update auth state
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Create a custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
