import React from 'react'
import AdminPanelStructure from './AdminPanelStructure';
import DefaultPage from './DefaultPage';

export const AdminPanelBase = () => {
  return (
    <>
    <AdminPanelStructure rightSidecontent={<DefaultPage/>} />
    </>
  )
}
export default AdminPanelBase;