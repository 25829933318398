// useFetchLinks.js
import { useState, useEffect } from 'react';

const useFetchLinks = (url) => {
  const [linkData, setLinkData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setLinkData(result.data.exploreLink);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLinkData([]); // Reset to an empty array in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchLinks();
  }, [url]);

  return { linkData, loading, error };
};

export default useFetchLinks;
