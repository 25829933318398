import React from 'react';
import ImportantDates from './detail-link-component/ImportantDates';
import AgeLimit from './detail-link-component/AgeLimit';
import Eligibility from './detail-link-component/Eligibility';
import ApplicationFee from './detail-link-component/ApplicationFee';
import Disclaimer from './detail-link-component/Disclaimer';
import VacancyDetail from './detail-link-component/VacancyDetail';
import ImportantLinks from './detail-link-component/ImportantLinks';

const Createnewjob = ({ 
  ageLimitData, 
  eligibilityData, 
  dateData, 
  applicationFeeData, 
  importantLinkData, 
  head, 
  mainHead, 
  vacancyDetails, 
  categoryWiseVacancyDetail,
  firstPara,
  secondPara,
  thirdPara 
}) => {

  return (
    <div className='flex flex-col items-center justify-center'>
      {/* Heading */}
      <h1 className='lg:text-2xl text-lg text-center font-bold p-3 w-[87vw] rounded-xl mt-2 shadow-md bg-blue-50 shadow-b text-sky-950'>
        {mainHead}
      </h1>
      <div 
        className='lg:text-lg text-md text-center font-semibold p-3 w-[77vw] rounded-xl shadow-md bg-yellow-50 mt-6' 
        dangerouslySetInnerHTML={{ __html: firstPara }} 
      />
    
      <div className='lg:w-[86vw] lg:flex justify-center'>
        <div className='pt-6 lg:w-[54vw] flex flex-col items-center'>
          <section className='md:p-2 lg:p-4 border border-slate-400 rounded-md md:w-full w-[98vw] mb-6'>
            {/* Important dates */}
            <ImportantDates head={head} dates={dateData} />
            {/* Age limit */}
            <AgeLimit ageLimitData={ageLimitData} />
            {/* Eligibility */}
            <Eligibility eligibilityData={eligibilityData} />
            {/* Vacancy details */}
            <VacancyDetail vacancyDetails={vacancyDetails} categoryWiseVacancyDetail={categoryWiseVacancyDetail} />
            {/* Application fee */}
            <ApplicationFee applicationFeeData={applicationFeeData} />
            {/* Important links */}
            <ImportantLinks importantLinkData={importantLinkData} />
          </section>

          <div 
            className='lg:text-md text-sm text-center p-3 w-[77vw] rounded-xl shadow-md bg-green-50 mt-2 mb-6' 
            dangerouslySetInnerHTML={{ __html: secondPara }} 
          />
          <div 
            className='lg:text-md text-sm text-center p-3 w-[77vw] rounded-xl shadow-md bg-lime-50 mt-2 mb-6' 
            dangerouslySetInnerHTML={{ __html: thirdPara }} 
          />

          <Disclaimer />
        </div>
      </div>
    </div>
  );
}

export default Createnewjob;
