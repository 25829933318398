import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to access URL params
import Createnewjob from '../../basic-pages/explore-structure/Createnewjob';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import config from '../../../config';

const ExploreStructure = () => {
  const { category, examName } = useParams(); // Access the dynamic URL parameters
  
  const [exploreState, setExploreState] = useState({
    // Overview
    dateData: [],
    head: [],
    ageLimitData: [],
    vacancyDetails: [],
    eligibilityData: [],
    applicationFeeData: [],
    importantLinkData: [],
    categoryWiseVacancyDetail: [],
    // Main
    mainHead: [],
    firstPara: [],
    secondPara: [],
    thirdPara: [],
    metaDataKeyword: '',
    metaDataDescription: '',
    metaDataTitle: '',
    loading: true,
    error: null
  });

  // Fetch data from backend 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(`${config.BASE_URL}/api/explore/overview/${examName}`);
        const response2 = await fetch(`${config.BASE_URL}/api/explore/main/${category}-${examName}`);

        if (!response1.ok || !response2.ok) {
          throw new Error('Failed to fetch data');
        }

        const result1 = await response1.json();
        const result2 = await response2.json();

        // Update the state with fetched data
        setExploreState(prevState => ({
          ...prevState,
          head: result1.data.Overview.overViewHead,
          dateData: result1.data.Overview.importantDate?.map(item => ({
            rowElement: item.colElement,
            rowElementresult: item.colElementResult
          })) || [],
          ageLimitData: result1.data.Overview.ageLimit?.map(item => ({
            rowElement: item.colElement,
            rowElementresult: item.colElementResult
          })) || [],
          vacancyDetails: result1.data.Overview.vacancyDetails?.map(item => ({
            rowElement: item.colElement,
            rowElementresult: item.colElementResult
          })) || [],
          eligibilityData: result1.data.Overview.eligibilityCriteria?.map(item => ({
            rowElement: item.colElement,
            rowElementresult: item.colElementResult
          })) || [],
          applicationFeeData: result1.data.Overview.applicationFee?.map(item => ({
            rowElement: item.colElement,
            rowElementresult: item.colElementResult
          })) || [],
          importantLinkData: result1.data.Overview.importantLinks?.map(item => ({
            rowElement: item.colElement,
            rowElementresult: item.colElementResult,
            resultLink: item.resultLink
          })) || [],
          categoryWiseVacancyDetail: result1.data.Overview.categoryWiseVacancyDetails?.map(item => ({
            postName: item.postName,
            ur: item.ur,
            sc: item.sc,
            st: item.st,
            obc: item.obc,
            ews: item.ews
          })) || [],
          // Main
          mainHead: result2.data.Main.mainHead,
          firstPara: result2.data.Main.firstPara,
          secondPara: result2.data.Main.secondPara,
          thirdPara: result2.data.Main.thirdPara,
          metaDataKeyword: result2.data.Main.metaDataKeyword || '',
          metaDataDescription: result2.data.Main.metaDataDescription || '',
          metaDataTitle: result2.data.Main.metaDataTitle || '',
          loading: false
        }));
      } catch (err) {
        setExploreState(prevState => ({
          ...prevState,
          error: err.message,
          loading: false
        }));
      }
    };

    fetchData();
  }, [category, examName]); // Use category and examName as dependencies

  if (exploreState.loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );

  if (exploreState.error)
    return <div>Error: {exploreState.error}</div>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{exploreState.metaDataTitle || 'Default Title'}</title>
        <meta name="description" content={exploreState.metaDataDescription || 'Default description'} />
        <meta name="keywords" content={exploreState.metaDataKeyword || 'Default keywords'} />
      </Helmet>
      <Createnewjob 
        mainHead={exploreState.mainHead}
        head={exploreState.head}
        ageLimitData={exploreState.ageLimitData} 
        eligibilityData={exploreState.eligibilityData} 
        dateData={exploreState.dateData}  
        importantLinkData={exploreState.importantLinkData} 
        applicationFeeData={exploreState.applicationFeeData} 
        vacancyDetails={exploreState.vacancyDetails} 
        categoryWiseVacancyDetail={exploreState.categoryWiseVacancyDetail}
        firstPara={exploreState.firstPara} 
        secondPara={exploreState.secondPara}
        thirdPara={exploreState.thirdPara}
      />
    </HelmetProvider>
  );
};

export default ExploreStructure;
