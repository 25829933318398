import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegArrowAltCircleRight } from "react-icons/fa";


const ExploreLink = ({ links, heading, linkPage }) => {
  
  return (
    <div className='w-[49vw] sm:w-[40vw] lg:w-[27vw] border h-full md:mb-2 justify-center shadow-lg rounded-lg'>
      <h1 className='font-semibold text-lg sm:text-xl lg:text-2xl text-center p-2 bg-gradient-to-r from-sky-800 to-sky-400 text-white rounded'>
        {heading}
      </h1>
      <ul>
        {links.slice(0, 12).map((link, index) => (
          <Link
            to={`explore/${link.urlName}`}
            key={index}
            
          >
            <li className='w-[96%] sm:p-[8px] p-[6px] text-sm shadow-sm m-1 text-center bg-white text-blue-700 text-md rounded-md hover:bg-slate-50 hover:shadow-md hover:text-red-500'>
              {link.linkLabel}
            </li>
          </Link>
        ))}
      </ul>
      <Link to={linkPage} className='flex text-lime-500 text-lg p-2 ml-auto justify-end hover:text-lime-600'>
        view more<FaRegArrowAltCircleRight className='pt-2 text-2xl' />
      </Link>
    </div>
  );
};

export default ExploreLink;
