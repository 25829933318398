import React from 'react';
import { FaFacebook, FaYoutube, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const ContactUs = () => {
  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-2xl p-6 lg:p-12">
        <h1 className="text-3xl lg:text-3xl font-bold text-center text-gray-800 mb-8">Get in Touch</h1>

        <div>
          <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-6">About SarkariLock.in</h2>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            At SarkariLock.in, we are passionate about transforming government job preparation. Our platform provides a comprehensive suite of resources designed to help you stay ahead of the competition. We offer detailed guides, exam preparation materials, and expert insights to ensure you are well-prepared and confident.
          </p>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            In addition to the latest job updates, we provide in-depth information on various government schemes (Sarkari-Yojanas). This means you get a complete view of the opportunities and benefits available to you, all in one place.
          </p>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            Our commitment is to deliver timely and reliable information, making SarkariLock.in your trusted partner in navigating the complexities of government exams and schemes. Join our community to access expertly curated content and support your journey to success.
          </p>

          <h3 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">Contact Us</h3>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            For inquiries, support, or further information, please reach out to us via email at:
            <a href="mailto:sarkarilock@gail.com" className="text-blue-600 hover:underline ml-1">sarkarilock@gmail.com</a>
          </p>

          <h3 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">Follow Us</h3>
          <p className="text-md lg:text-lg text-gray-600 mb-4">
            Stay connected with us through our social media channels for updates and more information:
          </p>
          <ul className='flex flex-wrap gap-6 mt-4 list-none justify-center'>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61564607174252&mibextid=ZbWKwL" aria-label="Facebook" target="_blank" rel="noopener noreferrer" className='text-blue-600 hover:text-blue-800 transition duration-300'>
                <FaFacebook size={32} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@sarkarilock1" aria-label="YouTube" target="_blank" rel="noopener noreferrer" className='text-red-600 hover:text-red-800 transition duration-300'>
                <FaYoutube size={32} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/@sarkarilock" aria-label="Twitter" target="_blank" rel="noopener noreferrer" className='text-blue-400 hover:text-blue-600 transition duration-300'>
                <FaXTwitter size={32} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/@sarkarilock?igsh=MW1hNDV6cW11ZjBia==" aria-label="Instagram" target="_blank" rel="noopener noreferrer" className='text-pink-600 hover:text-pink-800 transition duration-300'>
                <FaInstagram size={32} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
