import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CommingSoon from '../footer/CommingSoon';
import Home from './home/Home';
import SarkariYojana from '../pages/sarkari-yojana/SarkariYojana';
import ContactUs from './contact/ContactUs';
import AboutUs from './about/AboutUs';
import Base from './all-link-structure/Base';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy';

// Admin
import AdminPanelBase from '../admin/AdminPanelBase';
import ExploreOverview from '../admin/explore-overview/ExploreOverview';
import ExploreOverviewDetails from '../admin/explore-overview/ExploreOverviewDetails';
import ExploreMain from '../admin/explore-main/ExploreMain';
import ExploreMainDetails from '../admin/explore-main/ExploreMainDetails';
import LoginPage from '../admin/admin-auth/LoginPage';
import {useAuth} from '../admin/AuthContext'
import MaintenancePage from './MaintenancePage';

const BasicPagesRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Apply Helmet only on the Home route */}
      <Route
        path="/"
        element={
          <HelmetProvider>
            <>
              <Helmet>
                <title>SarkariLock</title>
                <meta
                  name="description"
                  content="Explore our Government Exam Information Portal for up-to-date details on exam dates, syllabi, eligibility criteria, and application processes. Stay informed on the latest job opportunities and government schemes."
                />
                <meta
                  name="keywords"
                  content="Government Exams, Exam Dates, Syllabus, Eligibility, Government Jobs, Exam Updates, Job Opportunities, Government Schemes"
                />
                <meta name="author" content="sarkarilock" />
              </Helmet>
              <Home />
            </>
          </HelmetProvider>
        }
      />
    
      {/* Other routes without Helmet */}
      <Route path="/comming-soon" element={<CommingSoon />} />
      <Route path="/sarkari-yojana" element={<SarkariYojana />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/search-exam" element={<Base />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      {/* Admin Routes */}
      <Route path="/admin-login" element={<LoginPage />} />

      {/* Redirect to admin-dashboard if authenticated, otherwise show login */}
      <Route path="/admin-dashboard" element={isAuthenticated ? <AdminPanelBase /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/overview" element={isAuthenticated ? <ExploreOverview /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/overview/detail/:examName" element={isAuthenticated ? <ExploreOverviewDetails /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/main" element={isAuthenticated ? <ExploreMain /> : <Navigate to="/admin-login" />} />
      <Route path="/admin/explore/main/detail/:category/:examName" element={isAuthenticated ? <ExploreMainDetails /> : <Navigate to="/admin-login" />} />
    </Routes>
  );
};

export default BasicPagesRoutes;
