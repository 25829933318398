import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container md:mx-auto md:py-10 sm:px-5 bg-slate-100 shadow-xl flex justify-center">
        <div className='lg:max-w-[60vw] bg-slate-50 md:p-12 rounded-lg p-4  w-[100vw]'>
      <section className="mb-10">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy for SarkariLock.in</h1>
        <p className="text-lg leading-relaxed text-gray-700">
          Last Updated: <strong>September 9, 2024</strong>
        </p>
        <p className="text-lg leading-relaxed text-gray-700 mt-4">
          At <span className="font-semibold text-indigo-600">SarkariLock.in</span>, your privacy is one of our main priorities. This Privacy Policy outlines the types of information we collect and record, and how we use it.
        </p>
      </section>

      {/* Information We Collect */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">1. Information We Collect</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          We collect a variety of information to enhance your experience and provide the best services possible. This includes personal and non-personal data as follows:
        </p>
        <ul className="list-disc ml-6 mt-4 text-gray-700">
          <li><strong>Personal Information:</strong> Name, email address, or any other contact details you provide when signing up or contacting us.</li>
          <li><strong>Non-Personal Information:</strong> IP addresses, browser type, pages visited, and other related data collected automatically.</li>
        </ul>
      </section>

      {/* How We Use Your Information */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">2. How We Use Your Information</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          The information collected helps us:
        </p>
        <ul className="list-disc ml-6 mt-4 text-gray-700">
          <li>Provide and improve our services.</li>
          <li>Respond to inquiries and support requests.</li>
          <li>Send you important updates, newsletters, or promotional materials (with your consent).</li>
          <li>Analyze usage patterns to optimize website performance.</li>
        </ul>
      </section>

      {/* Cookies and Tracking */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">3. Cookies and Tracking Technologies</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          We use cookies to improve your browsing experience. Cookies are small data files stored on your device that allow us to remember your preferences and track your activity on the site.
        </p>
        <p className="text-lg leading-relaxed text-gray-700 mt-4">
          You have the option to disable cookies through your browser settings, but note that this may impact your experience on our website.
        </p>
      </section>

      {/* Data Sharing */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">4. Data Sharing</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          We do not sell or trade your personal information with third parties. However, we may share information in the following circumstances:
        </p>
        <ul className="list-disc ml-6 mt-4 text-gray-700">
          <li>With trusted third-party services that help us operate our website.</li>
          <li>To comply with legal obligations or law enforcement requests.</li>
          <li>To protect the rights and safety of <strong>SarkariLock.in</strong>, its users, or the public.</li>
        </ul>
      </section>

      {/* Data Security */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">5. Data Security</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          We take security seriously and have implemented appropriate measures to safeguard your information. However, no data transmission over the Internet can be guaranteed to be 100% secure. We strive to use commercially acceptable means to protect your data but cannot guarantee absolute security.
        </p>
      </section>

      {/* Your Rights */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">6. Your Rights</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          Depending on your location, you may have rights related to your personal information, including:
        </p>
        <ul className="list-disc ml-6 mt-4 text-gray-700">
          <li>Accessing the personal information we hold about you.</li>
          <li>Requesting the correction of your personal data.</li>
          <li>Requesting the deletion of your data, subject to legal obligations.</li>
          <li>Opting out of marketing communications.</li>
        </ul>
        <p className="text-lg leading-relaxed text-gray-700 mt-4">
          To exercise these rights, please contact us at <strong>sarkarilock@gail.com</strong>.
        </p>
      </section>

      {/* External Links */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">7. Links to Other Websites</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          Our site may contain links to third-party websites. We are not responsible for the privacy practices of these external sites. We encourage you to review their privacy policies before interacting with them.
        </p>
      </section>

      {/* Changes to Privacy Policy */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">8. Changes to This Privacy Policy</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          We reserve the right to update this Privacy Policy from time to time to reflect changes in our practices. Any updates will be posted on this page with a revised "Last Updated" date.
        </p>
      </section>

      {/* Contact */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-4">9. Contact Us</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          If you have any questions or concerns regarding this Privacy Policy, feel free to reach out to us at:
        </p>
        <p className="text-lg leading-relaxed text-gray-700 mt-4">
          <strong>Email:</strong> sarkarilock@gail.com <br />
          
        </p>
      </section>

      {/* Disclaimer */}
      <section>
        <h2 className="text-2xl font-bold mb-4">Disclaimer</h2>
        <p className="text-lg leading-relaxed text-gray-700">
          While we strive to provide accurate and up-to-date information, <strong>SarkariLock.in</strong> does not guarantee the accuracy, completeness, or reliability of the information on this site. The information provided is for general informational purposes only and should not be considered as professional or legal advice. We are not liable for any errors or omissions in the information or for any actions taken based on it.
        </p>
      </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
