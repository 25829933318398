import React from "react";
import { Routes, Route } from "react-router-dom";

// Importing components for Explore structure
import ExploreStructure from "./ExploreStructure.jsx";

// See more links
import SeeMoreLinkAnswerKey from "../../basic-pages/home/home-components/explore/see-more-link/SeeMoreLinkAnswerKey.jsx";
import SeeMoreLinkAdmission from "../../basic-pages/home/home-components/explore/see-more-link/SeeMoreLinkAdmission.jsx";
import SeeMoreLinkAdmitCard from "../../basic-pages/home/home-components/explore/see-more-link/SeeMoreLinkAdmitCard.jsx";
import SeeMoreLinkLatestJob from "../../basic-pages/home/home-components/explore/see-more-link/SeeMoreLinkLatestJob.jsx";
import SeeMoreLinkResult from "../../basic-pages/home/home-components/explore/see-more-link/SeeMoreLinkResult.jsx";
import SeeMoreLinkSyllabus from "../../basic-pages/home/home-components/explore/see-more-link/SeeMoreLinkSyllabus.jsx";

const ExploreRoutes = () => {
  return (
    <Routes>
      {/* See more links */}
      <Route path="/answer-key/all-links" element={<SeeMoreLinkAnswerKey />} />
      <Route path="/admission/all-links" element={<SeeMoreLinkAdmission />} />
      <Route path="/admit-card/all-links" element={<SeeMoreLinkAdmitCard />} />
      <Route path="/result/all-links" element={<SeeMoreLinkResult />} />
      <Route path="/syllabus/all-links" element={<SeeMoreLinkSyllabus />} />
      <Route path="/latest-job/all-links" element={<SeeMoreLinkLatestJob />} />

      {/* Fetching link structure and passing identifier a+s a prop */}
      <Route
        path="/:category/:examName"
        element={<ExploreStructure />}
      />
    </Routes>
  );
};

export default ExploreRoutes;
