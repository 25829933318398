import React from 'react';


const ImportantDates = ({ head, dates }) => {
  return (
    <>
      <h1 className='text-xl text-center sm:text-2xl font-semibold text-orange-900 p-4 border-b-2 border-sky-900'>

      {head}
      </h1>
      <div className='p-2 md:p-4 shadow-lg mt-2'>
        <h2 className='font-semibold text-xl sm:text-2xl text-center p-2 bg-gradient-to-br from-sky-900 to-sky-400 text-white'>
          Important Dates
        </h2>
        <ol className='list-none'>
        {dates.map((data, index) => (
          <li className='flex justify-between text-sm md:text-lg border border-b-slate-400 p-2 pl-4 bg-slate-50 pr-2' key={index}>
          {data.rowElement} <p className='min-w-[45%] max-w-[45%] border-l-2 border-slate-600 pl-4 font-bold md:font-semibold text-red-700' >{data.rowElementresult}</p>
        </li>
        ))}
        </ol>
      </div>
      </>
  );
}

export default ImportantDates;
