import React from 'react'
import DoubleColumnElement from '../DoubleColumnElement';
import CategoryWiseVacancyDetail from './CategoryWiseVacancyDetail';
export const VacancyDetails = ({vacancyDetails,categoryWiseVacancyDetail}) => {
  return (
    <>
    <div className='md:p-4 shadow-lg mt-2'>
        <h2 className='font-semibold text-xl sm:text-2xl text-center p-2 bg-gradient-to-br from-sky-900 to-sky-400 text-white'>
          Vacancy Details
        </h2>
        <div className='flex justify-between text-lg border border-b-slate-400 pl-4 bg-slate-50 pr-2 text-slate-800 font-semibold'>
        Post Name <p className='min-w-[45%] max-w-[45%]  pl-4 font-semibold ' >Total Post</p>
      </div>
        <ol className='list-none'>
        {vacancyDetails.map((data, index) => (
          <DoubleColumnElement
            key={index}
            rowElement={data.rowElement}
            rowElementresult={data.rowElementresult}
          />
        ))}
        </ol>
        
        <CategoryWiseVacancyDetail categoryWiseVacancyDetail={categoryWiseVacancyDetail}/>
       
      </div>
    </>
  )
}
export default VacancyDetails;