exports.handleDataFileSubmit = async (url, formData, setFormData) => {
  const token = localStorage.getItem("jwt"); // Retrieve token
  
  if (!token) {
    alert("Authentication token is missing. Please log in again.");
    return;
  }

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}` // Include the token
      },
      body: formData,
      credentials: "include",
    });

    if (!response.ok) {
      console.error("Response status:", response.status); // Log the response status
      throw new Error("Failed to submit data");
    }

    alert("Data submitted successfully!");
    setFormData(null); // Reset the form state after a successful submission

  } catch (err) {
    alert(`Error submitting data: ${err.message}`);
  }
};
exports.handleDelete = async (url, navigateUrl, navigate) => {
  const token = localStorage.getItem("jwt"); // Retrieve token
  
  if (!token) {
    alert("Authentication token is missing. Please log in again.");
    navigate("/login"); // Redirect to login if token is missing
    return;
  }

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${token}` // Include the token
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to delete data");
    }

    alert("Data deleted successfully!");
    navigate(navigateUrl); // Redirect to the desired URL

  } catch (err) {
    alert(`Error deleting data: ${err.message}`);
  }
};
